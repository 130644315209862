<template>
  <span class="regular-12 text-black text-start">
    <button
      class="btn btn-secondary btn-sm me-2"
      v-if="!show"
      v-on:click="download()"
    >
      <BaseIcon name="barcode" />
    </button>
    <canvas ref="barcode" class="barcode" v-else></canvas>
    <button
      class="btn btn-secondary btn-sm"
      v-if="!show"
      v-on:click="downloadBox()"
    >
      <BaseIcon name="box" />
    </button>
  </span>
</template>

<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));
import BarCode from "jsbarcode";
import printJS from "print-js";
import { useStore } from "vuex";
import tenant from "../../../modules/tenant";
export default {
  name: "Serial",
  components: { BaseIcon },
  props: {
    id: Number,
    variantId: Number,
    type: String,
    row: Object,
    modelValue: String,
  },
  data() {
    return {
      store: useStore(),
      show: false,
      value: this.modelValue,
    };
  },
  watch: {
    show() {
      let _this = this;
      setTimeout(() => {
        BarCode(_this.$refs.barcode, _this.value, {
          displayValue: false,
          margin: 0,
          width: 3,
        });
      }, 100);
    },
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    download() {
      printJS(
        `${tenant.getApiBaseUrl()}/product/${
          this.variantId ?? this.id
        }/barcode?_token=${this.store.state.token}`
      );
    },
    downloadBox() {
      printJS(
        `${tenant.getApiBaseUrl()}/product/${
          this.variantId ?? this.id
        }/box-label?_token=${this.store.state.token}`
      );
    },
  },
};
</script>
